<template>
	<div class="userNews clearfix">
		<div class="userNewsLeft">
			<ul>
				<li :class="{ active: tabindex == 1 }" @click="clickActive(1)">
					订单列表
				</li>
				<li :class="{ active: tabindex == 2 }" @click="clickActive(2)">
					待开票
				</li>
				<li :class="{ active: tabindex == 3 }" @click="clickActive(3)">
					开票历史
				</li>
			</ul>
		</div>
		<!-- 订单列表 -->
		<div class="userNewsRight" v-if="tabindex == 1">
			<template v-if="orderlist.length > 0">
				<div class="userInvoice" @click="clickActive(2)">去开票</div>
				<div>
					<ul class="userOrder_table">
						<li>订单编号</li>
						<li>订单名称</li>
						<li>订单金额</li>
						<li>下单时间</li>
						<li>收货信息</li>
						<li>快递单号</li>
					</ul>
					<div class="userNewsRight_one" v-for="(item, index) of orderlist" :key="index">
						<div class="news_right">
							<!-- 编号 -->
							<div class="order_content">
								<p>{{ item.member_no }}</p>
							</div>
							<!-- 名称 -->
							<div class="order_content">
								{{ item.title }}
							</div>
							<!-- 金额 -->
							<div class="order_content">实付：¥{{ item.pay_price }}</div>
							<!-- 时间 -->
							<div class="order_content">
								{{ item.pay_time }}
							</div>
							<!-- 操作 -->
							<div class="order_content">
								<template v-if="item.is_exp==0">
									<div class="looks" v-if="item.name" @click="lookFun(item)">查看</div>
									<div class="write" v-else @click="writeFun(item.member_no)"> {{item.name&&item.name != 'null' ? '查看':'填写'}} </div>
								</template>
								<div class="looks">-</div>
							</div>
							<!-- 快递信息 -->
							<div class="order_content">
								{{ item.express ? item.express :'暂无快递信息' }}
								
							</div>
						</div>
					</div>
				</div>
				
				<el-drawer :visible.sync="islook" direction="ttb" :with-header="false" :destroy-on-close="true">
					<lookReceive :type="type" :info="info"></lookReceive>
				</el-drawer>

				<el-drawer :visible.sync="isReceive" direction="ttb" :with-header="false" :destroy-on-close="true">
					<Receive :type="type" :number="number" @close="closeDrawer" :tipnum="tipnum"></Receive> 
				</el-drawer>
 
				<div class="page">
					<page v-if="total > 12" :page="page" :limit="limit" :total="total" ref="page"></page>
				</div>
			</template>
			<template v-else>
				<div class="empty">
					<el-empty description="暂无数据"></el-empty>
				</div>
			</template>
		</div>
		<!-- 待开票 -->
		<div class="userNewsRight" v-if="tabindex == 2">
			<template v-if="Invoicelist.length > 0">
				<template v-if="!drawShow">
				<div>
					<ul class="userOrder_table userOrder_table_two">
						<li>订单编号</li>
						<li>订单名称</li>
						<li>实付金额</li>
						<li>可开发票金额</li>
					</ul>
					<div class="userNewsRight_one userNewsRight_two" v-for="(item, index) of Invoicelist" :key="index">
						<div class="downLoad_li_check" :class="item.check ? 'actives' : ''" @click="checkTwoFun(index)">
						</div>

						<div class="news_right">
							<!-- 编号 -->
							<div class="order_content">
								<p>{{ item.member_no }}</p>
							</div>
							<!-- 名称 -->
							<div class="order_content">
								{{ item.title }}
							</div>
							<!-- 金额 -->
							<div class="order_content">¥{{ item.pay_price }}</div>
							<!-- 可开发票金额 -->
							<div class="order_content">
								{{ item.pay_price }}
							</div>
						</div>
					</div>
				</div>

				<div class="all_select">
					<div class="all_select_left">
						<div class="downLoad_li_check" :class="checkAll ? 'actives' : ''" @click="checkAllFun()"></div>
						<p>全选</p>
					</div>
					<div class="all_select_right">
						可开具发票金额
						<span>¥{{ allPrice }}</span>
					</div>
				</div>

				<div class="invoice_btn" @click="drawFun()">开发票</div>
				</template>
				<!-- 开发票 -->
				<div class="drawBill" v-if="drawShow">
					<div class="drawBill_title">
						<div :class="{ activeD: itemindex == 1 }" @click="clickDraw(1)">
							增值税电子普通发票（个人）<i></i>
						</div>
						<div :class="{ activeD: itemindex == 2 }" @click="clickDraw(2)">
							增值税电子普通发票（公司）<i></i>
						</div>
					</div>
					<div class="drawBill_itembox" v-if="itemindex == 1">
						<div class="drawBill_item">
							<span>发票金额：</span>
							<p>{{ allPrice }}</p>
						</div>
						<div class="drawBill_item">
							<span>姓名：</span>
							<input type="text" name="" placeholder="请输入姓名" id="" v-model="name" />
						</div>
						<div class="drawBill_item">
							<span>身份证号：</span>
							<input type="text" name="" placeholder="请输入身份证号" id="" v-model="idcard" />
						</div>
						<div class="drawBill_item">
							<span>发票内容：</span>
							<p>服务费</p>
						</div>
						<div class="drawBill_item">
							<span>联系电话：</span>
							<input type="text" maxlength="11" placeholder="请输入联系电话" name="" id="" v-model="mobile" />
						</div>
						<div class="draw_btn">
							<div class="canclebtn" @click="cancleFun">取消</div>
							<div class="applybtn" @click="personalSubmit">提交开票申请</div>
						</div>
					</div>

					<div class="drawBill_itembox" v-if="itemindex == 2">
						<div class="drawBill_item">
							<span>发票金额：</span>
							<p>{{ allPrice }}</p>
						</div>
						<div class="drawBill_item">
							<span>公司名称：</span>
							<input type="text" name="" placeholder="请输入公司名称" id="" v-model="companyname" />
						</div>
						<div class="drawBill_item">
							<span>纳税人识别号：</span>
							<input type="text" name="" placeholder="请输入纳税人识别号" id="" v-model="taxpayer" />
						</div>
						<div class="drawBill_item">
							<span>地址、电话：</span>
							<input type="text" name="" placeholder="请输入地址、电话" id="" v-model="address" />
						</div>
						<div class="drawBill_item">
							<span>开户行及账号：</span>
							<input type="text" name="" placeholder="请输入开户行及账号" id="" v-model="bank_number" />
						</div>
						<div class="drawBill_item">
							<span>发票内容：</span>
							<p>服务费</p>
						</div>
						<div class="drawBill_item">
							<span>联系电话：</span>
							<input type="text" maxlength="11" placeholder="请输入联系电话" name="" id="" v-model="mobile" />
						</div>
						<div class="draw_btn">
							<div class="canclebtn" @click="cancleFun">取消</div>
							<div class="applybtn" @click="companySubmit">提交开票申请</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="empty">
					<el-empty description="暂无数据"></el-empty>
				</div>
			</template>
		</div>

		<!-- 开票历史 -->
		<div class="userNewsRight" v-if="tabindex == 3">
			<template v-if="Historylist.length > 0">
				<div>
					<ul class="userOrder_table userOrder_table_three">
						<li>发票申请时间</li>
						<li>发票抬头</li>
						<li>开票金额</li>
						<li>纳税识别号</li>
						<li>发票类型</li>
						<li>发票状态</li>
					</ul>
					<div class="userNewsRight_one userNewsRight_three" v-for="(item, index) of Historylist"
						:key="index">
						<div class="news_right">
							<!-- 发票申请时间 -->
							<div class="order_content">
								<p>{{ item.create_time }}</p>
							</div>
							<!-- 发票抬头 -->
							<div class="order_content">
								{{ item.name }}
							</div>
							<!-- 开票金额 -->
							<div class="order_content">¥{{ item.amount }}</div>
							<!-- 纳税识别号 -->
							<div class="order_content">
								{{ item.taxpayer }}
							</div>
							<!-- 发票类型 -->
							<div class="order_content">
								<div v-if="item.type == 1">个人</div>
								<div v-if="item.type == 2">公司</div>
							</div>
							<!-- 发票状态 -->
							<div class="order_content">
								<div v-if="item.invoice" class="downcolor" @click="downloadInvoic(item.invoice)">
									下载
								</div>
								<div v-else>待审核</div>
							</div>
						</div>
					</div>
				</div>
				<div class="page">
					<page v-if="total > 12" :page="page" :limit="limit" :total="total" ref="page"></page>
				</div>
			</template>
			<template v-else>
				<div class="empty">
					<el-empty description="暂无数据"></el-empty>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import page from "../../components/page.vue";
	import Receive from "../../components/receivingPopup.vue";
	import lookReceive from "../../components/lookReceive.vue";
	export default {
		name: "userOrder",
		components: {
			page,
			Receive,
			lookReceive
		},
		data() {
			return {
				orderlist: [], //订单列表
				Invoicelist: [], //待开票列表
				Historylist: [], //开票历史
				allPrice: 0, //可开具发票金额
				checkAll: false, //是否全选

				tabindex: 1,
				itemindex: 1,

				drawShow: false,

				idArry: [],
				name: "", //姓名
				idcard: "", //身份证号
				mobile: "", //联系电话
				address:"",//地址及电话
				bank_number:"",//开户行及账号
				

				companyname: "", //公司名称
				taxpayer: "", //纳税人识别号

				// 分页
				total: 0, //总数
				page: 1, //页数
				limit: 12, //每页个数

				isReceive: false,
				type: 1,
				
				islook:false,
				number:'',
				info:'',
				tipnum:1,
			};
		},
		mounted() {
			if (this.$route.query.type) {
				this.tabindex = this.$route.query.type;
			}
			
			if (localStorage.getItem("name")) {
				this.Consigneename = localStorage.getItem("name");
			}
			
			this.$parent.tab = 2;
			this.$parent.tabIndex = 6;
			this.$parent.$parent.routerIndex = 0;
			this.$parent.$parent.showIndex = 1;
			this.listFun();
			this.InvoiceFun();
			this.HistoryFun();
		},
		methods: {
			lookFun(info) {
				this.islook = true;
				this.info=info;
			},
			writeFun(menberno){
				this.number=menberno;
				this.isReceive = true;
			},
			closeDrawer(i) {
				// 支付完成后收货地址
				this.isReceive = false;
				
				if(i == 1){
					this.listFun()
				}
			},

			drawFun() {
				this.drawShow = true;
			},
			cancleFun() {
				this.drawShow = false;
			},

			// 订单列表
			listFun() {
				var that = this;
				this.$api.POST(
					this.$face.userOrder, {
						page: this.page,
						limit: this.limit,
					},
					function(res) {
						that.orderlist = res.data.list;
						that.total = res.data.total;
					}
				);
			},
			// 待开票列表
			InvoiceFun() {
				var that = this;
				this.$api.POST(
					this.$face.userInvoiced, {
						page: this.page,
						limit: this.limit,
					},
					function(res) {
						console.log(111, res);
						res.data.list.forEach((element) => {
							element.check = false;
						});
						that.Invoicelist = res.data.list;
						that.total = res.data.total;
					}
				);
			},

			// 多选
			checkTwoFun(index) {
				this.Invoicelist[index].check = !this.Invoicelist[index].check;
				this.sumFun();
			},
			// 全选
			checkAllFun() {
				this.checkAll = !this.checkAll;
				this.Invoicelist.forEach((item) => {
					item.check = this.checkAll;
				});
				this.sumFun();
			},
			// 计算选中价格
			sumFun() {
				var allPrice = 0;
				var allnum = "";
				var array = [];
				this.Invoicelist.forEach((item) => {
					if (item.check == true) {
						allnum++;
						allPrice += item.pay_price * 1;
						this.allPrice = allPrice;
						array.push(item.id);
					}
				});
				this.idArry = array;
				if (allnum == 0) {
					this.allPrice = 0;
				}
			},
			// 开发票tab切换
			clickDraw(index) {
				this.itemindex = index;
			},

			// 个人
			personalSubmit() {
				var that = this;
				if (!that.name) {
					that.$utile.prompt("error", "请填写姓名");
					return;
				}
				if (!that.idcard) {
					that.$utile.prompt("error", "请填写身份证号");
					return;
				}
				var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
				if (reg.test(that.idcard) === false) {
					that.$utile.prompt("error", "身份证输入不合法");
					return false;
				}
				if (!that.mobile) {
					that.$utile.prompt("error", "请填写联系电话");
					return;
				}

				var params = {
					type: 1,
					order_ids: this.idArry.join(","),
					amount: that.allPrice,
					name: that.name,
					idcard: that.idcard,
					mobile: that.mobile,
				};

				this.$api.POST(this.$face.memberInvoiceRecord, params, function(res) {
					that.$utile.prompt("success", "提交开票申请成功");

					setTimeout(function() {
						that.clickActive(3);
					}, 2000);
				});
			},
			// 公司
			companySubmit() {
				var that = this;
				if (!that.companyname) {
					that.$utile.prompt("error", "请填写公司名称");
					return;
				}
				if (!that.taxpayer) {
					that.$utile.prompt("error", "请填写纳税人识别号");
					return;
				}
				if (!that.mobile) {
					that.$utile.prompt("error", "请填写联系电话");
					return;
				}

				var params = {
					type: 2,
					order_ids: this.idArry.join(","),
					amount: that.allPrice,
					name: that.companyname,
					taxpayer: that.taxpayer,
					mobile: that.mobile,
					address:that.address,
					bank_number:that.bank_number
				};

				this.$api.POST(this.$face.memberInvoiceRecord, params, function(res) {
					that.$utile.prompt("success", "提交开票申请成功");
					setTimeout(function() {
						that.clickActive(3);
					}, 2000);
				});
			},

			// 开票历史
			HistoryFun() {
				var that = this;
				this.$api.POST(
					this.$face.userInvoicedHistory, {
						page: this.page,
						limit: this.limit,
					},
					function(res) {
						that.Historylist = res.data.list;
						that.total = res.data.total;
					}
				);
			},

			downloadInvoic(image) {
				window.open(image);
			},

			// 左侧导航选中
			clickActive(index) {
				this.tabindex = index;
				this.page = 1;
				this.listFun();
				this.InvoiceFun();
				this.HistoryFun();
			},
		},
		activated() {
			this.$nextTick(() => {
				this.$parent.tab = 3;
				this.$parent.tabIndex = 1;
				this.$parent.$parent.routerIndex = 0;
				this.$parent.$parent.showIndex = 1;
				this.allnum = 0; //选中数量
				this.checkAll = false; //是否全选
				this.page = 1;
				if (this.$refs.page) {
					this.$refs.page.currentPage = 1;
				}
				this.listFun();
				this.InvoiceFun();
				this.HistoryFun();
			});
		},
	};
</script>


<style scoped>
	.userNews>>>.el-drawer {
		height: 100% !important;
		box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%) !important;
	}
	.userOrder_table {
		width: 100%;
		height: 60px;
		background: #f4f6f6;
		border: 1px solid #f5f5f5;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		padding: 0 15px;
		box-sizing: border-box;
		margin-top: 25px;
	}

	.userOrder_table li {
		width:195px;
		font-size: 14px;
		color: #333333;
		line-height: 60px;
	}

	.userOrder_table_two li {
		padding-left: 30px;
	}

	.userOrder_table li:nth-child(3) {
		width: 110px;
	}
	.userOrder_table li:nth-child(4) {
		width: 150px;
	}
	.userOrder_table li:nth-child(6) {
		width: 210px;
	}
	.userInvoice {
		width: 100%;
		height: auto;
		overflow: hidden;
		text-align: right;
		font-size: 16px;
		color: #5857ff;
		line-height: 36px;
		cursor: pointer;
	}

	.userNewsRight_one {
		width: 100%;
		height: 100px;
		padding: 0 15px 30px;
		box-sizing: border-box;
		border-bottom: 1px solid #f5f5f5;
	}

	.order_content {
		width: 195px;
		height: 100%;
		font-size: 14px;
		color: #333333;
		line-height: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.order_content div{
		cursor: pointer;
	}								

	.order_content:nth-child(2) {
		color: #5957ff;
	}

	.order_content:nth-child(3) {
		width: 110px;
	}
	.order_content:nth-child(4) {
		color: #999999;
		width: 150px;
	}

	.order_content:nth-child(5),
	.userOrder_table li:nth-child(5) {
		width: 95px;
	}
	.order_content:nth-child(5){
		font-size: 14px;
	}
	.order_content:nth-child(6){
		width: 210px;
	}

	.userNewsRight_two .news_right .order_content {
		color: #333333;
	}

	.userNewsRight_two .news_right .order_content:nth-child(3) {
		color: #e80a0a;
	}

	

	.downLoad_li_check {
		width: 20px;
		height: 20px;
		background-color: #ffffff;
		border-radius: 3px;
		box-sizing: border-box;
		margin-right: 13px;
		cursor: pointer;
		border: 1px solid #dddddd;
	}

	.downLoad_li_check.actives {
		/* background-color: #5957FF;
		border-color: #5957FF; */
		background-image: url(../../assets/images/icon_xz.png);
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position: center center;
	}

	.userNewsRight {
		width: 75%;
		background: #fff;
		border-radius: 10px;
		float: right;
		padding: 15px 4% 30px;
		min-height: 500px;
		position: relative;
	}

	.userNewsRight_two {
		display: flex;
		align-items: center;
	}

	.userNewsRight_two .news_right {
		width: 98%;
	}

	.all_select {
		width: 100%;
		height: 100px;
		overflow: hidden;
		border-bottom: 1px solid #f5f5f5;
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		line-height: 100px;
		padding: 0 30px;
		box-sizing: border-box;
	}

	.all_select_left {
		width: 50%;
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.all_select_left p {
		font-size: 16px;
		color: #333333;
	}

	.all_select_right {
		font-size: 16px;
		color: #333333;
	}

	.all_select_right span {
		font-size: 32px;
		color: #e40a0a;
		display: inline-block;
		margin-left: 25px;
	}

	.invoice_btn {
		width: 160px;
		height: 40px;
		background: #5857ff;
		border-radius: 4px;
		margin: 40px auto 60px;
		font-size: 18px;
		color: #ffffff;
		line-height: 40px;
		text-align: center;
		cursor: pointer;
	}

	.userNewsRight_three .order_content {
		font-size: 14px;
	}

	.userNewsRight_three .order_content:nth-child(4) {
		color: #333333;
	}

	.userNewsRight_three .order_content:nth-child(3) {
		color: #e80a0a;
		width: 110px;
	}


	.userNewsRight_three .order_content:nth-child(2) {
		color: #333333;
	}

	.userOrder_table_three li:nth-child(6),
	.userOrder_table_three li:nth-child(3) {
		width: 110px;
	}

	.userOrder_table_three li:nth-child(1),
	.userNewsRight_three .order_content:nth-child(1) {
		width: 180px;
	}

	.userNewsRight_three .order_content:nth-child(6) {
		width: 110px;
	}

	.downcolor {
		color: #5957ff;
		cursor: pointer;
	}

	.drawBill {
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: #fff;
	
	}

	.drawBill_title {
		width: 100%;
		height: 60px;
		border-bottom: 1px solid #ededed;
		display: flex;
		justify-content: center;
		padding-top: 16px;
		box-sizing: border-box;
	}

	.drawBill_title div {
		width: 300px;
		height: auto;
		overflow: hidden;
		line-height: 40px;
		font-size: 16px;
		color: #333333;
		cursor: pointer;
		text-align: center;
	}

	.drawBill_title div i {
		display: block;
		width: 80px;
		height: 3px;
		background: transparent;
		margin: auto;
	}

	.drawBill_title div.activeD {
		color: #5957ff;
	}

	.drawBill_title div.activeD i {
		background: #5957ff;
	}

	.drawBill_itembox {
		width: 50%;
		height: auto;
		overflow: hidden;
		margin: 40px auto 0;
	}

	.drawBill_item {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.drawBill_item span {
		display: block;
		width: 150px;
		height: auto;
		overflow: hidden;
		text-align: right;
		line-height: 40px;
	}

	.drawBill_item:nth-child(2) span::before,
	.drawBill_item:nth-child(3) span::before,
	.drawBill_item:nth-child(7) span::before {
		content: "*";
		color: #da0707;
		margin-right: 2px;
	}

	.drawBill_item p {
		width: 70%;
		font-size: 16px;
		color: #333333;
		line-height: 40px;
	}

	.drawBill_item input {
		width: 70%;
		height: 40px;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.draw_btn {
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 90px;
	}

	.draw_btn div {
		width: 160px;
		height: 40px;
		border-radius: 5px;
		font-size: 16px;
		line-height: 40px;
		text-align: center;
		margin: 0 10px;
	}

	.canclebtn {
		border: 1px solid #cccccc;
		color: #999999;
	}

	.applybtn {
		background: #5957ff;
		color: #ffffff;
	}

	.userNews {
		min-height: 400px;
		min-width: 1200px;
		max-width: 1400px;
		width: 100%;
		margin: 0 auto 39px;
		padding-top: 13px;
	}

	.userNewsLeft {
		background-color: #fff;
		border-radius: 10px;
		width: 15%;
		float: left;
	}

	.userNewsLeft ul {
		padding: 37px 0 0;
		overflow: hidden;
	}

	.userNewsLeft li {
		font-size: 16px;
		color: #333;
		padding-left: 37px;
		margin-bottom: 37px;
		cursor: pointer;
		height: 20px;
		line-height: 20px;
		position: relative;
	}

	.userNewsLeft li::before {
		display: block;
		width: 6px;
		height: 20px;
		content: " ";
		background-color: #fff;
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 3px;
	}

	.userNewsLeft li.active::before {
		background-color: #5957ff;
	}

	.userNewsLeft li.active {
		color: #5957ff;
	}

	.news_right {
		display: flex;
		justify-content: space-between;
	}

	.news_right_desc {
		font-size: 16px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #333333;
		line-height: 26px;
		width: 90%;
	}

	.news_right_desc pre {
		word-wrap: break-word;
		white-space: pre-wrap;
		font-family: MicrosoftYaHei;
	}

	.page {
		padding: 20px 0;
	}

	.empty {
		font-size: 14px;
		color: #ccc;
		text-align: center;
		padding: 60px 0 40px;
	}
	.write{
		color: #5957ff;
	}
</style>
