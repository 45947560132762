<template>
	<div class="receive_box">
		<!-- 收货弹窗 -->
		<div class="buy_close">
			<div class="el-icon-close" @click="close"></div>
		</div>
		<div class="receiveBox">
			<div class="drawBill_item">
				<span>订单编号：</span>
				<p>{{info.member_no}}</p>
			</div>
			<div class="drawBill_item">
				<span>收货人：</span>
				<p>{{info.name}}</p>
			</div>
			<div class="drawBill_item">
				<span>手机号：</span>
				<p>{{info.mobile}}</p>
			</div>
			<div class="drawBill_item">
				<span>收货地址：</span>
				<p>{{info.address}}</p>
			</div>
			<div class="drawBill_item">
				<span>快递信息：</span>
				<p>{{info.express ? info.express: '暂无快递信息'}}</p>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		props: ['type','info'],
		data() {
			return {
		
			}
		},
		mounted() {
		
		},
		methods: {
			close() {
				this.$parent.closeDrawer();
			},
			
		}
	}
</script>

<style>
	.receive_box {
		width: 500px;
		height: 400px;
		background-color: #ffffff;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.buy_close {
		height: 38px;
		text-align: right;
		font-size: 22px;
		color: #a0a1a1;
		margin-top: 15px;
		margin-right: 15px;
		line-height: 38px;
		cursor: pointer;
	}

	.receiveBox {
		width: 100%;
		height: auto;
		overflow: hidden;
		padding: 0 50px;
		box-sizing: border-box;
		margin-top: 0;
	}

	.receiveBox .drawBill_item {
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.drawBill_item span {
		display: block;
		width: 110px;
		height: auto;
		overflow: hidden;
		text-align: right;
		line-height: 40px;
	}

	.drawBill_item p {
		width: 70%;
		font-size: 16px;
		color: #333333;
		line-height: 40px;
	}

	.drawBill_item input {
		width: 70%;
		height: 40px;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		padding:0 10px;
		box-sizing: border-box;
		font-size: 16px;
		color: #333333;
	}

	.drawBill_item input::placeholder {
		color: #dcdfe6;
		font-size: 14px;
	}

	.draw_btn {
		width: 100%;
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
	}

	.draw_btn div {
		width: 160px;
		height: 40px;
		border-radius: 5px;
		font-size: 16px;
		line-height: 40px;
		text-align: center;
		margin: 0 10px;
	}

	.canclebtn {
		border: 1px solid #cccccc;
		color: #999999;
	}

	.applybtn {
		background: #5957ff;
		color: #ffffff;
		cursor: pointer;
	}
</style>
